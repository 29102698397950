<template>
  <div class="thing_category_add">
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="12">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            订单详情
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
    
      <div class="box-card">
        <div class="info">
          <p>基本信息</p>
        </div>
        <el-row class="text item">
          <el-col :span="8"><span class="s">订&nbsp;&nbsp;单&nbsp;&nbsp;号：</span><span class="u">{{order_info.order_sn}}</span></el-col>
          <el-col :span="8"><span class="s">订单状态：</span><span class="u">{{order_info.status_name}}</span></el-col>
          <el-col :span="8"><span class="s">下单时间：</span><span class="u">{{order_info.add_time}}</span></el-col>
        </el-row>
        <div class="info">
          <p>支付信息</p>
        </div>
        <el-row class="text item">
          <el-col :span="8"><span class="s">支付方式：</span><span class="u">{{order_info.pay_name}}</span></el-col>
          <el-col :span="8"><span class="s">支付状态：</span><span class="u">{{order_info.pay_status_name}}</span></el-col>
          <el-col :span="8"><span class="s">支付时间：</span><span class="u">{{order_info.pay_time}}</span></el-col>
        </el-row>
        <div class="info">
          <p>收货人信息</p>
        </div>
        <el-row class="text item">
          <el-col :span="8"><span class="s">收&nbsp;&nbsp;货&nbsp;&nbsp;人：</span><span class="u">{{order_info.consignee}}</span></el-col>
          <el-col :span="8"><span class="s">收货手机：</span><span class="u">{{order_info.mobile}}</span></el-col>
          <el-col :span="24"><span class="s">收货地址：</span><span class="u">{{order_info.area}}{{order_info.address}}</span></el-col>
        </el-row>
        <div class="info">
          <p>物流信息</p>
        </div>
        <el-row class="text item">
          <el-col :span="8"><span class="s">物流方式：</span><span class="u">{{order_info.shipping_name}}</span></el-col>
          <el-col :span="8"><span class="s">物流状态：</span><span class="u">{{order_info.shipping_status_name}}</span></el-col>
          <el-col :span="8"><span class="s">发货时间：</span><span class="u">{{order_info.shipping_time}}</span></el-col>
          <el-col :span="8"><span class="s">快递单号：</span><span class="u">{{order_info.shipping_no}}</span></el-col>
        </el-row>
        <div class="info">
          <p>商品信息</p>
        </div>
        <div>
          <el-table
            :data="goods_list"
            :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
            style="width: 100%">
            <el-table-column prop="goods_name" label="名称"></el-table-column>
            <el-table-column prop="goods_attr" label="属性" width="300"></el-table-column>
            <el-table-column prop="goods_sn" label="编号"></el-table-column>
            <el-table-column label="价格">
              <template slot-scope="scope">
                <div>
                  &yen;{{scope.row.shop_price}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goods_number" label="数量"></el-table-column>
            <el-table-column label="小计">
              <template slot-scope="scope">
                <div>
                  &yen;{{scope.row.goods_number*scope.row.shop_price}}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: right; margin-top: 10px;">合计：&yen;{{order_info.goods_amount}}，支付：&yen;{{order_info.order_amount}}</div>
      </div>

      <div v-if="order_info.pay_status==2">
        <el-button type="primary" @click="showShipping" 
        v-if="order_info.pay_status==2&&order_info.order_status==1&&order_info.shipping_status==0">立即发货</el-button>
        <el-button type="default" @click="completeOrder" 
        v-if="order_info.pay_status==2&&order_info.order_status==1&&order_info.shipping_status!=3">完成</el-button>

        <el-button type="default" @click="deleteOrder" 
        v-if="order_info.pay_status==0&&order_info.order_status==0">删除</el-button>
      </div>
    </el-card>

    <el-drawer
      title="发货"
      :visible.sync="drawer"
      direction="rtl"
      class="drawer">
      <div class="drawer-content">
        <el-form ref="Form" :model="order_info">
          <el-form-item label="发货方式" prop="shipping_type">
            <el-select v-model="order_info.shipping_type" placeholder="请选择属性类型">
              <el-option label="快递发货" value="1"></el-option>
              <el-option label="物流发货" value="2"></el-option>
              <el-option label="自提货物/虚拟订单/线下订单" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="快递方式" prop="shipping_id" v-if="order_info.shipping_type==1">
            <el-select v-model="order_info.shipping_id" placeholder="请选择快递">
              <el-option
                v-for="(item,index) in shipping_list"
                :key="index"
                :label="item.shipping_name"
                :value="item.shipping_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流名称" prop="shipping_name" v-if="order_info.shipping_type==2">
            <el-input v-model="order_info.shipping_name" placeholder="请输入物流名称"></el-input>
          </el-form-item>
          <el-form-item label="单号" prop="shipping_no" v-if="order_info.shipping_type==1||order_info.shipping_type==2">
            <el-input v-model="order_info.shipping_no" placeholder="请输入单号"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="subShipping">提交</el-button>
        <el-button type="default" @click="back">返回</el-button>
      </div>
    </el-drawer>


  </div>
</template>

<script>
import Upload from '@/components/Upload/Upload.vue'
export default {
  components:{
    Upload
  },
  data () {
    return {
      drawer: false,
      order_info: {},
      goods_list: [],
      shipping_list: [],
    }
  },
  created() {
    if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			if(this.$route.query.order_id){
				this.getDetail();
			}
		}
  },
  methods: {
  	getDetail(){
  		var url = 'thing/order_info';
			let params = {
				order_id: this.$route.query.order_id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
          this.order_info = res.order_info;
          this.goods_list = res.goods_list;
					this.shipping_list = res.shipping_list;
				} else {
					this.$message.error(res.msg);
				}
			}).catch((err) => {
				this.$message.error('网络错误');
			});
  	},
    back(){
      this.$router.go(-1)
    },
    showShipping() {
      this.drawer = true;
    },
    subShipping() {
      var url = 'thing/order_shipping';
      let params = {
        order_id: this.order_info.order_id,
        shipping_type: this.order_info.shipping_type,
        shipping_id: this.order_info.shipping_id,
        shipping_name: this.order_info.shipping_name,
        shipping_no: this.order_info.shipping_no,
      };
      this.fd_post(url, params).then((res) => {
        if(res.status) {
          this.getDetail();
          this.drawer = false;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    completeOrder() {
      this.$confirm('此操作将完成订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var url = 'thing/order_complete';
        let params = {
          order_id: this.order_info.order_id
        };
        this.fd_post(url, params).then((res) => {
          if(res.status) {
            this.getDetail();
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      }).catch(() => {
                 
      });
    },
    deleteOrder() {
      this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var url = 'thing/order_delete';
        let params = {
          order_id: this.order_info.order_id
        };
        this.fd_post(url, params).then((res) => {
          if(res.status) {
            this.$router.push({path: '/thing_order'});
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      }).catch(() => {
                 
      });
    }
  },
}
</script>

<style lang="scss" scoped>
  .thing_category_add{
    .left{
      text-align: left;
    }
    .right{
      text-align: right;
    }
    .clearfix{
      text-align: left;
    }
    .tile{
      margin: 20px 0;
      .el-col{
        color: #666;
      }
    }
    .box-card{
      margin-bottom: 30px;
      .info {
          padding: 8px 16px;
          background-color: #ecf8ff;
          border-radius: 4px;
          border-left: 5px solid #50bfff;
          margin: 20px 0;
          p {
              line-height: 30px;
              text-align: left;
              font-size: 14px;
          }
      }
    }
    .item{
      text-align: left;
      padding-left: 20px;
      .el-col{
        height: 30px;
        display: flex;
        align-items: center;
        .s{
          width: 78px;
          text-align: right;
          font-size: 14px;
          color: #555;
        }
        .u{
          text-align: left;
          font-size: 14px;
          color: #333;
        }
      }
    }
    .drawer{
      text-align: left;
      .drawer-content{
        padding: 20px;
        .el-select{
          width: 100%;
        }
      }
    }
  }
</style>